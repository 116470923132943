import { Tag, TagProps } from '@applyboard/crystal-ui'
import { ApplicationState } from 'applications-types-lib'
import { ApplicationState as AppStatus } from '../../../hooks'

type ApplicationStateTagProps = {
  state?: ApplicationState
}

export default function ApplicationStateTag(props: ApplicationStateTagProps) {
  const getStateInfo = () => {
    let intent = 'primary' as TagProps['intent']
    let label = 'Submitted'

    switch (props.state) {
      case AppStatus.DECLINED:
        intent = 'negative'
        label = 'Declined'
        break
      case AppStatus.PAYMENT_PROCESSING:
        intent = 'magic' as TagProps['intent']
        label = 'Payment Processing'
        break
      case AppStatus.INITIAL_OFFER:
        intent = 'positive'
        label = 'Pre-Offer'
        break
      case AppStatus.DOCUMENTS_REQUESTED_IN_SCREENING:
      case AppStatus.DOCUMENTS_REQUESTED_IN_ASSESSMENT:
        intent = 'warning'
        label = 'Documents Requested'
        break

      case AppStatus.DRAFT:
        intent = 'warning'
        label = 'Draft'
        break
      case AppStatus.DEFERRAL_REQUESTED_IN_ASSESSMENT:
      case AppStatus.DEFERRAL_REQUESTED_IN_SCREENING:
      case AppStatus.DEFERRAL_REQUESTED_IN_INITIAL_OFFER:
        intent = 'warning'
        label = 'Deferral Requested'
        break
    }

    return {
      intent,
      label,
    }
  }

  const { intent, label } = getStateInfo()

  return (
    <Tag icon="fill" intent={intent}>
      {label}
    </Tag>
  )
}
