import { useToasts } from '@applyboard/crystal-ui'
import { useQueryClient } from '@tanstack/react-query'
import { useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { RawApplicationAggregationResponse } from './useGetApplication'
import { getBaseUrl } from '../../utils/request/getBaseUrl'
import { ApplicationResource } from 'applications-types-lib'
import { InitContext } from '../../config/initializers'
import { ApplicationState } from './types'

type FlywireActionProps = {
  applicationId: string
  onSuccess: () => void
}

export function useFlywireAction(props: FlywireActionProps) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const toast = useToasts()
  const context = useContext(InitContext)
  const hiddenButtonRef = useRef<HTMLButtonElement>(null)

  async function handleOnClick() {

    if (!hiddenButtonRef.current) {
      throw new Error('Hidden button ref is not set')
    }

    await queryClient.invalidateQueries({
      queryKey: ['applications', props.applicationId],
    })

    const response = (await queryClient.getQueryData([
      'applications',
      props.applicationId,
    ])) as RawApplicationAggregationResponse

    const updatedApplication = response.data.attributes.application

    if (updatedApplication.attributes?.applicationState !== ApplicationState.DRAFT) {
      toast.negative(
        new Error(
          'Payment in progress: Another payment is already being processed for this application',
        ),
      )
      navigate(`/applications/${props.applicationId}`)
    } else {
      hiddenButtonRef.current?.click()
      props.onSuccess()
    }
  }

  function setFlywireDialogTrigger(application: ApplicationResource) {
    const schoolId = application.relationships.school.data.id
    const appId = application.id
    const baseUrl = getBaseUrl()

    const config = {
      ...context.flywireConfig,

      // Pre-fill form values
      amount: application.attributes?.applicationFee?.amount,
      sender_country:
        application.attributes?.personalInformation?.addresses?.residentialAddress?.country,
      sender_phone: application.attributes?.personalInformation?.contactInformation?.phoneNumber,
      sender_first_name:
        application.attributes?.personalInformation?.basicPersonalInformation?.givenName,
      sender_middle_name:
        application.attributes?.personalInformation?.basicPersonalInformation?.middleName,
      sender_last_name:
        application.attributes?.personalInformation?.basicPersonalInformation?.familyName,
      sender_address1:
        application.attributes?.personalInformation?.addresses?.residentialAddress?.addressLine1,
      sender_city: application.attributes?.personalInformation?.addresses?.residentialAddress?.city,
      sender_email: application.attributes?.personalInformation?.contactInformation?.email,

      // Callback values
      student_first_name:
        application.attributes?.personalInformation?.basicPersonalInformation?.givenName,
      student_last_name:
        application.attributes?.personalInformation?.basicPersonalInformation?.familyName,
      student_email: application.attributes?.personalInformation?.contactInformation?.email,
      student_date_of_birth: '',

      // Callback configuration
      return_url: `${baseUrl}/applications/new?applicationId=${appId}`,
      callback_id: `${schoolId}#${appId}`,
      callback_version: '2',
      callback_url: `${baseUrl}/api/payments`,
    }

    window.flywire.Checkout.render(config, '#checkout-button')
  }

  return {
    hiddenButtonRef,
    handleOnClick,
    setFlywireDialogTrigger,
  }
}
